export const seo = {
  url: 'polityka-firmy',
  title: {
    pl: 'Dokumenty | Polityka Firmy | Komunikaty',
    en: 'Documents | Company policy | Messages',
    zh: '文件 | 公司政策 | 信息',
  },
  desc: {
    pl: 'Zapoznaj się z dokumentacją Omida Logistics, polityką prywatności, jakości oraz rekrutacji. Sprawdź najnowsze komunikaty zarządu.',
    en: 'Familiarize yourself with Omida Logistics documentation, privacy, quality and recruitment policy. Check the latest board announcements.',
    zh: '熟悉 Omida Logistics 文档、隐私、质量和招聘政策。查看最新的董事会公告。',
  },
  keywords: [],
}

export const intro = {
  title: {
    pl: 'Polityka Firmy',
    en: 'Company Policies',
    zh: '公司政策',
  },
  desc: {
    pl: 'Zapoznaj się z dokumentacją Omida Logistics, polityką prywatności, jakości oraz rekrutacji. Sprawdź najnowsze komunikaty zarządu.',
    en: 'Familiarize yourself with Omida Logistics documentation, privacy, quality and recruitment policy. Check the latest board announcements.',
    zh: '熟悉 Omida Logistics 文档、隐私、质量和招聘政策。查看最新的董事会公告。',
  },
  button: {
    text: {
      pl: 'Szukaj dokumentu',
      en: 'Explore documentation',
      zh: '探索文件',
    },
    action: 'SCROLL',
  },
}
